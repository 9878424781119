import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import {window, document} from "browser-monads"

import agbaLogo from "../images/agba-logo.png"
import Footer from "../components/footer"

const Header = () => {
  const
    [visible, setVisible] = useState(false),
    {pathname} = window.location;

  useEffect(() => {
    const
      [red, green, blue] = [255, 255, 255],
      color = document.querySelector('.contact-color'),
      noColor = document.querySelector('.no-color');

    if(visible) {
      document.getElementsByTagName('body')[0].classList.add('overflow-none')
    } else {
      document.getElementsByTagName('body')[0].classList.remove('overflow-none')
    }

    if(pathname == "/contact/") {
      window.onscroll = function(ev) {
        let y = 1 + (window.scrollY || window.pageYOffset) / 13000
        y = y < 1 ? 1 : y
        const [r, g, b] = [red/y, green/y, blue/y].map(Math.round)
        if (!!color) {
          color.style.backgroundColor = `rgb(${r}, ${g}, ${b})`
        }
      }
    } else {
      window.onscroll = function(ev) {
        if (!!noColor) {
          noColor.style.backgroundColor = `rgb(255, 255, 255)`
        }
      }
    }
  })

  return(
    <React.Fragment>
      <header className="sticky-header" data-sal="fade" data-sal-easing="ease-out-cubic" style={{"--sal-duration": "2s", "--sal-delay": "2.7s"}}>
        <Link to="/"><img src={agbaLogo} alt="AGBA Weddings logo"/></Link>
        <nav>
          <Link to="/about/">About</Link>
          <Link to="/">Weddings</Link>
          <Link to="/contact/">Contact</Link>
        </nav>
        <div className="nav--mobile" onClick={() => setVisible(!visible)}>
          <span className={visible ? "nav--mobile-icon open" : "nav--mobile-icon"}></span>
          <span className={visible ? "nav--mobile-icon open" : "nav--mobile-icon"}></span>
        </div>
      </header>
      <div className={!!visible ? "menu--mobile open" : "menu--mobile"}>
        <div className="menu--mobile-links">
          <Link to="/about/" onClick={() => setVisible(!visible)}>About</Link>
          <Link to="/" onClick={() => setVisible(!visible)}>Weddings</Link>
          <Link to="/contact/" onClick={() => setVisible(!visible)}>Contact</Link>
        </div>
        <div className="menu--mobile-footer">
          <Footer/>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Header
