import React, {useState, useEffect} from "react"
import {useStaticQuery, graphql} from "gatsby"
import { window } from "browser-monads"
import { Helmet } from 'react-helmet'
import classNames from 'classnames'
import PropTypes from "prop-types"
import Img from "gatsby-image"

import Header from "./header"
import Footer from "./footer"
import icon from '../images/agba-icon.svg'
import whiteIcon from '../images/agba-white-icon.svg'
import "./layout.scss"
import "./custom.scss"

import logoWhite from "../images/agba-logo-white.svg"

const Layout = ({children}) => {
  const landing = useStaticQuery(
    graphql`
    {
      sanityLanding {
        image {
          asset {
            fluid(maxWidth: 2560) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
        verticalImage {
          asset {
            fluid(maxWidth: 1280) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
    }
  `
  );
  const dark = window.matchMedia('(prefers-color-scheme:dark)');

  const
    { pathname } = window.location,
    [firstTime, setFirstTime] = useState(true),
    [orientation, setOrientation] = useState(null),
    image = landing.sanityLanding.image,
    vImage = landing.sanityLanding.verticalImage;

  let
    wrapperClass = classNames({
      'wrapper': pathname === "/about/",
    }),
    changeColor = classNames({
      'contact-color': pathname === "/contact/",
      'no-color': pathname !== "/contact/"
    });

  useEffect(() => {
    if(window.innerHeight >= window.innerWidth) {
      setOrientation("portrait")
    } else {
      setOrientation("landscape")
    }

    if(!!firstTime && pathname === "/") {
      document.getElementsByTagName('body')[0].classList.add('overflow-none')

      setTimeout(function(){
        document.getElementsByClassName('introduction-desktop')[0].classList.add('disappear')
      }, 900);

      setTimeout(function(){
        setFirstTime(false);
      }, 1350);

    } else if(!!firstTime && pathname !== "/") {
      setFirstTime(false)
    } else {
      document.getElementsByTagName('body')[0].classList.remove('overflow-none')
    }
  })

  return (
    <>
      <Helmet>
        <link rel="icon" type="image/png" href={dark.matches ? whiteIcon : icon} />
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-K2NQS3Q');
          `}
        </script>
        <noscript>
          {`
             <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K2NQS3Q"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `}
        </noscript>
      </Helmet>
      <div className={changeColor}>
        <Header/>
          <div id="wrapper">
            {!!firstTime && pathname === "/" &&(
              <div className="introduction-desktop">
                {orientation === 'portrait' && (
                  <Img fluid={vImage.asset.fluid} alt="AGBA Weddings" backgroundColor="#F2F2F2"/>
                )}
                {orientation === 'landscape' && (
                  <Img fluid={image.asset.fluid} alt="AGBA Weddings" backgroundColor="#F2F2F2"/>
                )}
                <img className="logo-intro" src={logoWhite} alt="AGBA Weddings Ana Georgina Ampudia"
                data-sal="fade" data-sal-delay="250" data-sal-easing="ease" data-sal-duration="700"/>
              </div>
            )}
            <main className={wrapperClass}>{children}</main>
          </div>
        <Footer/>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
