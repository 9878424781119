import { Link } from "gatsby"
import React from "react"
import agbaIcon from "../images/agba-icon.png"
import classNames from 'classnames'
import { window } from "browser-monads"

const Footer = () => {
  const { pathname } = window.location;
  let
    footerClass = classNames({
      'footer-white': pathname === "/about/",
    });

  return(
    <footer className={footerClass} data-sal="slide-up" data-sal-delay="1000" data-sal-easing="ease-out-cubic" style={{"--sal-duration": "1.2s"}}>
      <div id="border">
        <hr/>
      </div>
      <div className="main-container">
        <div className="image-container">
          <img src={agbaIcon} alt="AGBA Weddings Icon"/>
        </div>
        <div className="work-container">
          <Link to="/contact/"><p>Contact</p></Link>
          <Link to="/"><p>Weddings</p></Link>
        </div>
        <div className="sn-container">
          <a href="https://www.instagram.com/agba.weddings/" target="__blank"><p>Instagram</p></a>
          <a href="https://www.facebook.com/agba.weddings/" target="__blank"><p>Facebook</p></a>
        </div>
        <div className="rights-container">
          <p>&copy; Copyright 2020 AGBA</p>
          <p>All rights reserved</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
